.App {
  text-align: center;
  font-family: 'Roboto';
  font-weight: 300;
}

.App-logo-wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 3vmin; */
  /* padding: -12px; */
  height: 16vmin;
  width: 32vmin;
  padding-top: 4px;
  padding-left: 12px;
  /* pointer-events: none; */
  border-radius: 8px;
  background: #0d0221;
  overflow: hidden;
  margin: 8px;
  border: 2px solid;
  border-color: #0d0221;
}

.App-logo-wrapper:hover {
  border-color: #fd1d53;
}

.App-logo-wrapper img {
  max-height: 100%;
  max-width: 100%;
}

.App-logo {
  margin: 8px;  
  height: 20vmin;
  pointer-events: none;
}



.App-container {
  background-color: #1C0658;
  min-height: 80vh;
  padding: 10vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 1vmin);
  color: #FF1690;
}

.title {
  font-size: calc(12px + 3vmin);
  font-weight: 900;
  /* margin-top: 10vh; */
}

.subtext {
  color: #a5a5a5
}

.logo-wrapper {
  display: flex;
  /* flex-direction: column; */
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
